import { Button, DatePicker, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import moment from "moment";
// import "antd/dist/antd.css";
import ModalCommon from "../Modal";
import API, { HISTORY_STAR, Ticket3 } from "../../network/api";
import dayjs from "dayjs";

const SettingWinner = () => {
  const [form] = Form.useForm();
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const [isShowPopupFinal, setIsShowPopupFinal] = useState(false);
  const [formData, setFormData] = useState();
  const [textSuccess, setTextSuccess] = useState();

  useEffect(() => {
    getDataPersonWinner();
  }, []);

  const getDataPersonWinner = async () => {
    const currentDate = new Date();
    // const dayFomat = moment(currentDate).utcOffset(14);
    // console.log(dayFomat);

    const kw = encodeURIComponent(
      JSON.stringify({
        effectivedAt: currentDate,
      })
    );
    try {
      const res = await API.get(
        `${Ticket3}/admin-all?take=10&skip=1&order=DESC&keyword=${kw}`
      );
      const data = res.data.data.result[0];
      if (data.length > 0) {
        const newArray = data.map((item) => {
          return {
            id: item.id,
            username: item.username,
            point: item.point,
            multiple: item.multiple,
            effectivedAt: item.effectivedAt,
          };
        });
        setFormData(newArray[0]);
        console.log(moment(newArray[0].effectivedAt));
        form.setFieldValue(
          "selectedDate",
          newArray[0].effectivedAt
            ? moment(newArray[0].effectivedAt)
            : null
        );
      } else {
        setFormData(null);
      }
    } catch (err) { }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShowPopup = () => {
    setIsShowPopupConfirm(true);
  };

  const handleSubmit = async () => {
    try {
      setIsShowPopupConfirm(false);
      setIsShowPopupFinal(true);

      const body = {
        username: formData?.username,
        point: Number(formData?.point),
        multiple: Number(formData?.multiple),
        effectivedAt: formData?.dateString ? formData?.dateString : moment(formData?.effectivedAt).format('YYYY-MM-DD'),
      };

      if (formData.id) {
        const res = await API.patch(`${Ticket3}/${formData.id}/update`, body);
        if (res.data.code !== 20001 || res.data.code !== 20004) {
          setTextSuccess("Cập nhật không thành công!");
        } else {
          setTextSuccess("Cập nhật thành công!");
          await getDataPersonWinner();
        }
        form.resetFields();
      } else {
        const res = await API.post(HISTORY_STAR.createTicket3, body);
        if (res.data.code !== 20001) {
          setTextSuccess("Cập nhật không thành công!");
        } else {
          setTextSuccess("Cập nhật thành công!");
          await getDataPersonWinner();
        }
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disabledDate = (current) => {
    const currentDate = new Date();

    const currentDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return current && current < currentDay;
  };

  const onChange = (date, dateString) => {

    setFormData({
      ...formData,
      effectivedAt: date,
      dateString: dateString
    })
    console.log("DATE", moment(date).format("DD-MM-YYYY"))
  }

  return (
    <div className="col-12 mt-5">
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        style={{
          maxWidth: 800,
        }}
      >
        <Form.Item label={TEXT_DEF.userName}>
          <Input
            value={formData?.username}
            name="username"
            onChange={(e) => handleChangeInput(e)}
          />
        </Form.Item>
        <Form.Item label={TEXT_DEF.point}>
          <Input
            type="number"
            min={0}
            value={+formData?.point}
            name="point"
            style={{ width: "20%" }}
            onChange={(e) => handleChangeInput(e)}
          />
        </Form.Item>
        <Form.Item label={TEXT_DEF.number_round}>
          <Input
            type="number"
            min={0}
            value={formData?.multiple}
            name="multiple"
            style={{ width: "20%" }}
            onChange={(e) => handleChangeInput(e)}
          />
        </Form.Item>
        <Form.Item label="Chọn ngày" >
          <DatePicker
            value={formData?.effectivedAt ? dayjs(formData.effectivedAt) : null}
            onChange={onChange}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button htmlType="submit" type="primary" onClick={handleShowPopup}>
            {TEXT_DEF.text0026}
          </Button>
        </Form.Item>
      </Form>

      {isShowPopupConfirm && (
        <ModalCommon
          isShow={isShowPopupConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleSubmit}
          close={() => setIsShowPopupConfirm(false)}
        />
      )}
      {isShowPopupFinal && (
        <ModalCommon
          isShow={isShowPopupFinal}
          message={textSuccess}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowPopupFinal(false);
          }}
          close={() => {
            setIsShowPopupFinal(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingWinner;
